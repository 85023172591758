import { track } from '@amplitude/analytics-browser'
import {
  Creation,
  CreationBase,
  CreationOutput,
  ErrorCodeEnum,
  Nilable,
  Plan,
  Size,
  type AnyObject,
  type ApiError,
  type ApiResponse,
} from '@/types'
import axios, { type AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import dayjs, { Dayjs } from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import durationPlugin from 'dayjs/plugin/duration'
import omitBy from 'lodash-es/omitBy'
import isNil from 'lodash-es/isNil'
import * as Sentry from '@sentry/nextjs'
import { toast } from '@/components/ui/use-toast'
import {
  AMPLITUDE_USER_ID_KEY,
  AMPLITUDE_USER_CREATE_TIME_KEY,
  firebaseBucketPrefix,
  PROD_HOST,
  TEST_DOMAINS,
  TEST_HOST,
  VALID_PARTERS,
  CACHE_KEY_SUBSCRIPTION_REMIND,
  UTM_FIELDS,
} from '@/constants'
import { getDefaultStore } from 'jotai'
import { auth0SignInAtom, showBlockedDialogAtom, subscriptionDialogContentAtom } from '@/atoms'
// import axiosRetry from 'axios-retry'
import { Auth0ProviderOptions } from '@auth0/auth0-react'
import { createAuth0Client, Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'
import { confirm } from '@/components/dialog'
import { restoreAccountApi } from '@/service/profile.service'
import getSymbolFromCurrency from 'currency-symbol-map'
import { createTailwindMerge, getDefaultConfig } from 'tailwind-merge'
import { filesize } from 'filesize'
import qs from 'qs'
import {
  safeJsonParse,
  getLocalStorage,
  getSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from './base'
import sleep from 'sleep-promise'
import { capitalize } from 'lodash-es'

export {
  safeJsonParse,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
}

dayjs.extend(utcPlugin)
dayjs.extend(durationPlugin)

const knownErrorCodes: string[] = [
  ErrorCodeEnum.AUTH_FIREBASE_TOKEN_EXPIRED,
  ErrorCodeEnum.AUTH_INVALID_TOKEN,
  ErrorCodeEnum.AUTH_BLOCKED,
  ErrorCodeEnum.AUTH_TOKEN_EXPIRED,
  ErrorCodeEnum.SHARE_ACCESS_DENIED,
  ErrorCodeEnum.SHARE_NOT_FOUND,
  ErrorCodeEnum.SHARE_PRIVATE,
  ErrorCodeEnum.PRE_DELETE_ACCOUNT,
  ErrorCodeEnum.RATE_EXCEEDED,
  ErrorCodeEnum.QUOTA_EXCEEDED,
  ErrorCodeEnum.NOT_ALLOWED_PRIVATE_GENERATION,
]

export const isLocalDev =
  (process.env.NODE_ENV === 'development' ||
    process.env.NEXT_PUBLIC_RUNTIME_ENV === 'dev' ||
    (typeof location !== 'undefined' && location.hostname === 'localhost')) &&
  process.env.NEXT_PUBLIC_API_ENV !== 'pro'

const isTest =
  typeof location !== 'undefined' &&
  (TEST_DOMAINS.includes(location.hostname) || /^testapp\d+\.haiper\.ai$/.test(location.hostname))

export const isStage = typeof location !== 'undefined' && location.hostname === 'testappstage.haiper.ai'

export const isProduction = !isLocalDev && !isTest

const useReverseProxy = isTest && !TEST_DOMAINS.includes(location.hostname)

export const rest = axios.create({
  baseURL: useReverseProxy ? '/api' : 'https://api1.haiper.ai/',
  timeout: 10 * 60 * 1000,
  headers: {
    'x-vision-env': process.env.NEXT_PUBLIC_API_ENV ?? (isLocalDev || isTest ? 'dev' : 'pro'),
    'x-haiper-client': 'webapp',
    'x-haiper-version': process.env.NEXT_PUBLIC_BUILD_ID,
    'x-haiper-auth': 'auth0',
  },
})

export const clearAuthCache = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('profile')
  localStorage.removeItem(AMPLITUDE_USER_ID_KEY)
  localStorage.removeItem(AMPLITUDE_USER_CREATE_TIME_KEY)

  for (const key of Object.keys(localStorage)) {
    if (key.startsWith('@@auth0spajs@@')) {
      localStorage.removeItem(key)
    }
  }
}

let auth0Client: Auth0Client | null = null
const refreshToken = async (force = false) => {
  try {
    const oldToken = getLocalStorage('token', { raw: true })
    if (!oldToken) {
      return
    }

    if (typeof location !== 'undefined' && location.protocol !== 'https:' && !isLocalDev) {
      return
    }

    if (!auth0Client) {
      auth0Client = await createAuth0Client(getAuth0Params())
    }
    const params: GetTokenSilentlyOptions = {
      cacheMode: force ? 'off' : 'on',
    }
    const newToken = await auth0Client.getTokenSilently(params)

    if (newToken && newToken !== oldToken) {
      setLocalStorage('token', newToken, { raw: true })
      const jotaiStore = getDefaultStore()
      jotaiStore.set(auth0SignInAtom, true)
    }
  } catch (error: any) {
    whisper('refreshToken error: ', error)
    if (error?.error === 'invalid_grant') {
      clearAuthCache()
      auth0Client?.logout({
        logoutParams: {
          returnTo: `${window.location.origin}/auth/signin${location.search}`,
        },
      })
    }
  }
}

// dynamic set authorization header
rest.interceptors.request.use(
  async (config) => {
    await refreshToken()
    const token = getLocalStorage('token', { raw: true })
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  async (error) => {
    return await Promise.reject(error)
  },
)

const handleErrorResponse = async (response: any): Promise<any> => {
  const error = response?.data?.error as ApiError | undefined

  if (error?.code === ErrorCodeEnum.PRE_DELETE_ACCOUNT) {
    confirm({
      title: 'Restore your account?',
      message: `It looks like you recently deleted your account. Would you like to restore your account and all associated data? You have ${error?.days_to_expire || 30} days from the deletion date to recover your account.`,
      okText: 'Restore account',
      onCancel: async () => {
        location.replace(`/auth/signout?redirect=${encodeURIComponent(window.location.pathname)}`)
        return Promise.resolve()
      },
      onOk: async () => {
        return restoreAccountApi().then((res) => {
          if (res.status === 200) {
            location.reload()
          }
        })
      },
    })
  }

  if (
    error?.code === ErrorCodeEnum.AUTH_TOKEN_EXPIRED ||
    error?.code === ErrorCodeEnum.AUTH_INVALID_TOKEN ||
    error?.code === ErrorCodeEnum.AUTH_FIREBASE_TOKEN_EXPIRED
  ) {
    try {
      await refreshToken(true)
    } catch (error) {
      console.error('refreshToken error:', error)
      location.replace(`/auth/signout?redirect=${encodeURIComponent(window.location.pathname)}`)
    }
  }

  if (error?.code === ErrorCodeEnum.AUTH_BLOCKED) {
    const jotaiStore = getDefaultStore()
    jotaiStore.set(showBlockedDialogAtom, true)
  }

  if (
    error?.code === ErrorCodeEnum.RATE_EXCEEDED ||
    error?.code === ErrorCodeEnum.QUOTA_EXCEEDED ||
    error?.code === ErrorCodeEnum.NOT_ALLOWED_PRIVATE_GENERATION
  ) {
    const jotaiStore = getDefaultStore()
    jotaiStore.set(subscriptionDialogContentAtom, {
      code: error.code,
      message: error.message ?? '',
    })
  }

  if (!(response?.config as any)?.meta?.silent && !knownErrorCodes.includes(String(error?.code))) {
    toast({
      title: error?.message ?? response?.data?.err_msg ?? (typeof error === 'string' ? error : 'Network Error'),
      color: 'error',
    })
  }

  if (knownErrorCodes.includes(String(error?.code))) {
    return Promise.resolve(response.data)
  }
  return Promise.reject(response)
}

rest.interceptors.response.use(
  async (response: AxiosResponse<ApiResponse>) => {
    const { data } = response
    const error = (data as any)?.error as ApiError | undefined
    if (error) {
      return await handleErrorResponse(response)
    }

    response.data = checkPagination(data ?? null)
    return response
  },
  async (error: any) => {
    whisper('network.exception.error is: ', error)
    return await handleErrorResponse(error?.response)
  },
)

axiosRetry(rest, {
  retries: 1,
  retryCondition(error) {
    const data: any = error?.response?.data ?? (error as any)?.data
    const errorCode = data?.error?.code
    const result =
      errorCode === ErrorCodeEnum.AUTH_TOKEN_EXPIRED ||
      errorCode === ErrorCodeEnum.AUTH_INVALID_TOKEN ||
      errorCode === ErrorCodeEnum.AUTH_FIREBASE_TOKEN_EXPIRED
    if (result) {
      console.error('random result: ', result)
    }
    return result
  },
  onMaxRetryTimesExceeded(error, retryCount) {
    // logout and redirect to login page
    location.replace(`/auth/signout?redirect=${encodeURIComponent(window.location.pathname)}`)
  },
})

/**
 * render null or undefined to '--'
 * @param value
 * @returns
 */
export const neverEmpty = <T>(value: T | null | undefined, fallback: T | string = '--'): T | string => {
  if (typeof value === 'number' && isNaN(value)) return fallback
  if (value === '') return fallback
  return value ?? fallback
}

// export const ne = neverEmpty

export const formatTime = (time: string | number | Date | undefined, format: string = 'YYYY-MM-DD HH:mm'): string => {
  if (!time) return '--'
  const dateObj = dayjs(time)
  if (!dateObj.isValid()) return '--'
  return dateObj.format(format)
}

export const checkPagination = (res: any) => {
  if (!res) return null
  if (res.pagination) {
    return {
      total: res?.pagination?.total_size ?? 0,
      pageSize: res?.pagination?.page_size ?? 10,
      current: res?.pagination?.current ?? 1,
      next: res?.pagination?.next ?? null,
      limit: res?.pagination?.limit ?? 10,
      size: res?.pagination?.size ?? 0,
      records: res.value,
    }
  }
  return res.value
}

export const isImage = (fileId: string) => {
  const exts = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'webp',
    'psd',
    'svg',
    'tiff',
    'tif',
    'jfif',
    'ico',
    'dib',
    'jpe',
    'jfif',
    'jfi',
    'jp2',
    'j2k',
    'jpf',
    'jpx',
    'jpm',
    'mj2',
    'svgz',
    'ai',
    'eps',
    'ps',
    'cdr',
    'raw',
    'wmf',
    'emf',
    'lic',
    'fli',
    'flc',
  ]
  return exts.includes((fileId?.split('.').pop() ?? '').toLowerCase())
}

export const isVideo = (fileId: string) => {
  const exts = [
    'mp4',
    'avi',
    'mov',
    'mpeg',
    'mpg',
    'mkv',
    'flv',
    'wmv',
    'rmvb',
    'webm',
    'rm',
    '3gp',
    'ts',
    'mts',
    'm2ts',
    'vob',
    'f4v',
    'asf',
    'divx',
    'm4v',
    'dat',
    'mpe',
    'mpv',
    'm2v',
    '3g2',
    'mxf',
    'roq',
    'nsv',
    'ogv',
    'tp',
    'drc',
    'xvid',
    'm2p',
    'm2t',
    'amv',
    'm2v',
    'qt',
    'yuv',
    'rm',
    'rmvb',
    'ogm',
    'ogv',
    'ogx',
  ]
  return exts.includes((fileId?.split('.').pop() ?? '').toLowerCase())
}

// export const isVideoPlaying = (video: HTMLVideoElement | null | undefined) => {
//   if (!video) return false
//   const result = !!(
//     video.currentTime > 0 &&
//     !video.paused &&
//     !video.ended &&
//     video.readyState > 2
//   )
//   return result
// }

export const isSameUnorderedArray = (arr1: any[], arr2: any[]) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
  if (arr1.length !== arr2.length) return false
  return arr1.every((item) => arr2.includes(item))
}

export const extractFilenameFromFileId = (fileId: string) => {
  return fileId?.split('/').pop() ?? null
}

export const compactObj = <T extends Record<string, any> = Record<string, any>>(obj: T): T => {
  return omitBy(obj, isNil) as T
}

export const todo = () => {
  toast({
    title: 'Todo',
  })
}

const generateImageThumbnail = async (
  file: File,
  mime = 'image/png',
  outputWidth?: number,
): Promise<{ dataUrl: string; mime: string }> => {
  const image = new Image()
  image.src = URL.createObjectURL(file)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('Canvas not supported')
  await new Promise((resolve, reject) => {
    image.onload = () => {
      const { width, height } = image
      const ratio = width / height
      const thumbnailWidth = outputWidth ?? width
      const thumbnailHeight = thumbnailWidth / ratio
      canvas.width = thumbnailWidth
      canvas.height = thumbnailHeight
      ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight)
      resolve(null)
    }
    image.onerror = (error) => {
      reject(error)
    }
  })
  URL.revokeObjectURL(image.src)
  const dataUrl = canvas.toDataURL(mime)
  return {
    dataUrl,
    mime,
  }
}

const generateVideoThumbnail = async (
  file: File,
  mime = 'image/png',
  outputWidth?: number,
): Promise<{ dataUrl: string; mime: string }> => {
  const video = document.createElement('video')
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('Canvas not supported')

  await new Promise((resolve, reject) => {
    video.setAttribute('src', URL.createObjectURL(file))
    video.crossOrigin = 'Anonymous'
    video.load()
    video.onerror = (error) => {
      console.error('error when loading video: ', error)
    }
    video.onloadedmetadata = () => {
      setTimeout(() => {
        video.currentTime = 0.1
      }, 200)
      video.onseeked = () => {
        const { videoWidth, videoHeight } = video
        const ratio = videoWidth / videoHeight
        const thumbnailWidth = outputWidth ?? videoWidth
        const thumbnailHeight = thumbnailWidth / ratio
        canvas.width = thumbnailWidth
        canvas.height = thumbnailHeight
        ctx.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight)
        resolve(null)
      }
    }
    video.onerror = (error) => {
      reject(error)
    }
  })
  URL.revokeObjectURL(video.src)
  const dataUrl = canvas.toDataURL(mime)
  return {
    dataUrl,
    mime,
  }
}

export const generateThumbnail = async (
  file: File,
  mime = 'image/png',
  outputWidth?: number,
): Promise<{ dataUrl: string; mime: string }> => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('Canvas not supported')

  // image or video
  if (file.type.startsWith('image')) {
    return await generateImageThumbnail(file, mime, outputWidth)
  } else if (file.type.startsWith('video')) {
    return await generateVideoThumbnail(file, mime, outputWidth)
  }
  return {
    dataUrl: '',
    mime: '',
  }
}

export const generateVideoThumbnailFromUrl = async (
  url: string,
  mime = 'image/png',
  outputWidth?: number,
): Promise<{ dataUrl: string; mime: string }> => {
  if (!url) {
    return {
      dataUrl: '',
      mime,
    }
  }

  const video = document.createElement('video')
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('Canvas not supported')

  await new Promise((resolve, reject) => {
    video.setAttribute('src', url)
    video.crossOrigin = 'Anonymous'
    video.load()
    video.onerror = (error) => {
      console.error('error when loading video: ', error)
    }
    video.onloadedmetadata = () => {
      setTimeout(() => {
        video.currentTime = 0.1
      }, 200)
      video.onseeked = () => {
        const { videoWidth, videoHeight } = video
        const ratio = videoWidth / videoHeight
        const thumbnailWidth = outputWidth ?? videoWidth
        const thumbnailHeight = thumbnailWidth / ratio
        canvas.width = thumbnailWidth
        canvas.height = thumbnailHeight
        ctx.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight)
        resolve(null)
      }
    }
    video.onerror = (error) => {
      reject(error)
    }
  })
  URL.revokeObjectURL(video.src)
  const dataUrl = canvas.toDataURL(mime)
  return {
    dataUrl,
    mime,
  }
}

// export const formatFilename = (filename: string, suffix: string) => {
//   // convert space and commas to dashes
//   filename = filename.replace(/[ ,]/g, '-')

//   // limit max filename length
//   const maxFilenameLength = 100
//   if (filename.length > maxFilenameLength) {
//     filename = filename.substring(0, maxFilenameLength)
//   }
//   return `${filename}${suffix}`
// }

export const withFirebaseBucketPrefix = (path: Nilable<string>) => {
  return path && !path?.includes('://') ? `${firebaseBucketPrefix}/${path}` : path
}

export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds = Math.floor(duration % 60)
  const result = [
    hours > 0 ? String(hours) : '',
    hours > 0 ? String(minutes).padStart(2, '0') : String(minutes),
    String(seconds).padStart(2, '0'),
  ]
    .filter((e) => e !== '')
    .join(':')
  return result
}

export const getVideoResolution = async (url: string) => {
  let result = { width: 0, height: 0 }
  let maxRetry = 3

  try {
    while (maxRetry > 0) {
      maxRetry--
      try {
        result = await new Promise((resolve, reject) => {
          const video = document.createElement('video')
          video.onloadedmetadata = () => {
            resolve({
              width: video.videoWidth,
              height: video.videoHeight,
            })
          }
          video.onerror = (error) => {
            reject(error)
          }

          video.preload = 'metadata'
          video.src = url
          video.crossOrigin = 'Anonymous'
          video.load()
        })
        break
      } catch (error) {
        if (maxRetry === 0) {
          throw error
        }
      }
    }

    return result
  } catch (error) {
    Sentry.captureException(error)
    track('error:get-video-resolution', { url, error: (error as Error)?.message })
    return { width: 0, height: 0 }
  }
}

export const getImageResolution = async (url: string) => {
  let result = { width: 0, height: 0 }
  let maxRetry = 3
  try {
    while (maxRetry > 0) {
      maxRetry--
      try {
        result = await new Promise((resolve, reject) => {
          const image = new Image()
          image.src = url
          image.crossOrigin = 'Anonymous'
          image.onload = () => {
            resolve({
              width: image.width,
              height: image.height,
            })
          }
          image.onerror = (error) => {
            reject(error)
          }
        })
        break
      } catch (error) {
        if (maxRetry === 0) {
          throw error
        }
      }
    }

    return result
  } catch (error) {
    Sentry.captureException(error)
    track('error:get-video-resolution', { url, error: (error as Error)?.message })
    return { width: 0, height: 0 }
  }
}

export const preventDefault = (e: Event) => {
  e?.preventDefault()
}

export const stopPropagation = (e: Event) => {
  e?.stopPropagation()
}

export const preventDefaultAndStopPropagation = (e: Event) => {
  preventDefault(e)
  stopPropagation(e)
}

export const copyText = async (text: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    console.error('copyText error:', error)
    throw error
  }
}

export const isMobile = () => {
  if (typeof window === 'undefined') return false
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const getShareOrigin = () => {
  if (typeof window === 'undefined') {
    if (process.env.NEXT_PUBLIC_ORIGIN) {
      return process.env.NEXT_PUBLIC_ORIGIN
    }
    if (isProduction) {
      return PROD_HOST
    }
    return TEST_HOST
  }
  if (window.location.hostname === 'localhost') return TEST_HOST
  return window.location.origin
}

export const whisper = (...args: any[]) => {
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.debug(...args)
  }
}

export function dataURLtoBlob(dataUrl: string) {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

// export const url2File = async (
//   url: string,
//   filename: string,
// ): Promise<File | null> => {
//   try {
//     const response = await fetch(url)
//     const blob = await response.blob()
//     const file = new File([blob], filename)
//     return file
//   } catch (error) {
//     return null
//   }
// }

export const utcDate = (time?: string | Dayjs | Date | number) => {
  return dayjs.utc(time ?? Date()).format('YYYY-MM-DD')
}

export const utcTime = (time?: string | Dayjs | Date | number) => {
  return dayjs.utc(time ?? Date()).format('HH:mm:ss')
}

export const utcDateTime = (time?: string | Dayjs | Date | number) => {
  return dayjs.utc(time ?? Date()).format('YYYY-MM-DD HH:mm:ss')
}

export const formatDurationAbbr = (start: string | Dayjs | Date | number, end: string | Dayjs | Date | number) => {
  const diff = dayjs.duration(dayjs(end).diff(dayjs(start)))
  const units = [
    { unit: 'y', value: Math.floor(diff.asYears()) },
    { unit: 'M', value: Math.floor(diff.asMonths()) },
    { unit: 'd', value: Math.floor(diff.asDays()) },
    { unit: 'h', value: Math.floor(diff.asHours()) },
    { unit: 'm', value: Math.floor(diff.asMinutes()) },
  ]

  for (const { unit, value } of units) {
    if (value >= 1) {
      return `${value}${unit}`
    }
  }
  return 'Just now'
}

export const formatRelativeTime = (target: string | Dayjs | Date | number, current: string | Dayjs | Date | number) => {
  const parsedTarget = dayjs(target)
  const parsedCurrent = dayjs(current)

  const diff = dayjs.duration(parsedCurrent.diff(parsedTarget))
  const diffHours = Math.floor(diff.asHours())
  const diffMinutes = Math.floor(diff.asMinutes())
  if (diffHours === 0) {
    return diffMinutes > 0 ? `${diffMinutes}m` : 'Just now'
  }
  if (diffHours < 24) {
    return `${diffHours}h`
  }
  if (parsedCurrent.startOf('day').isSame(parsedTarget.startOf('day').add(1, 'day'))) {
    return 'Yesterday'
  }

  if (parsedCurrent.startOf('year').isSame(parsedTarget.startOf('year'))) {
    // Sep 4
    return parsedTarget.format('MMM D')
  }

  // Sep 4, 2021
  return parsedTarget.format('MMM D, YYYY')
}

export const round2 = (num: number) => {
  return Math.round(num * 100) / 100
}

export const getVideoDuration = async (url: string): Promise<number> => {
  try {
    const resultPromise = cancelableGetVideoDuration(url).promise
    const duration = await resultPromise
    return duration
  } catch (error) {
    whisper('error when getting video duration: ', error)
    return 0
  }
}

export const cancelableGetVideoDuration = (
  url: string,
): {
  promise: Promise<number>
  abort: () => void
} => {
  const video = document.createElement('video')
  video.src = url
  video.crossOrigin = 'Anonymous'
  video.load()

  const abort = () => {
    video.pause?.()
    video.src = ''
    video.load()
  }

  const promise = new Promise<number>((resolve, reject) => {
    video.onloadedmetadata = () => {
      resolve(video.duration)
    }
    video.onerror = (error) => {
      resolve(0)
    }
  }).catch((error) => {
    return 0
  })

  return {
    promise,
    abort,
  }
}

export const getContentAreaWidth = (windowWidth: number) => {
  let width = Math.min(1728, windowWidth ?? window.innerWidth)
  const mdScreenWidth = 1000
  const wideScreenWidth = 1448
  const sidebarWidth = width >= wideScreenWidth ? 226 : width < mdScreenWidth ? 0 : 66
  const xPadding = width >= mdScreenWidth ? 64 : 16
  return width - sidebarWidth - xPadding * 2
}

export const openNewTab = (url: string) => {
  // open in new tab, without block
  const newTab = window.open(url, '_blank')
  newTab?.focus()
}

const isEmbed = () => {
  if (typeof window === 'undefined') return false
  return window.self !== window.top
}

export const getInitialPartner = (): string | null => {
  if (!isEmbed() && !isLocalDev) {
    return null
  }

  const partner = getSessionStorage<string>('partner')
  if (partner && VALID_PARTERS.includes(partner)) {
    return partner
  }

  if (typeof window !== 'undefined') {
    try {
      if (location.pathname.startsWith('/embed/')) {
        const partner = location.pathname.split('/')[2]
        if (partner && VALID_PARTERS.includes(partner)) {
          setSessionStorage('partner', partner)
          return partner
        }
      }
    } catch (error) {
      return null
    }
  }

  return null
}

const getOrigin = () => {
  if (typeof window === 'undefined') {
    return isProduction ? PROD_HOST : TEST_HOST
  }
  return window.location.origin
}

export const getAuth0Params = (): Auth0ProviderOptions => {
  const clientId = isProduction
    ? process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_PRO
    : process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_DEV
  const domain = isProduction ? process.env.NEXT_PUBLIC_AUTH0_DOMAIN_PRO : process.env.NEXT_PUBLIC_AUTH0_DOMAIN_DEV

  return {
    useRefreshTokens: true,
    domain: domain || '',
    clientId: clientId || '',
    authorizationParams: {
      redirect_uri: `${getOrigin()}/auth/callback/auth0`,
      audience: 'https://api1.haiper.ai/v1',
    },
    cacheLocation: 'localstorage',
  }
}

export const formatCDN = (url: string | undefined) => {
  return url
    ?.replace('https://cdnvb1.haiper.ai/', 'https://cdnvb5.haiper.ai/')
    .replace('https://cdnvb2.haiper.ai/', 'https://cdnvb5.haiper.ai/')
    .replace('https://cdnvb3.haiper.ai/', 'https://cdnvb5.haiper.ai/')
    .replace('https://cdnvb4.haiper.ai/', 'https://cdnvb5.haiper.ai/')
}

export const formatMoneyIntl = (amount: number | null | undefined, currency = 'USD') => {
  if (isNil(amount) || isNaN(amount)) return '--'

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amount)
}

export const formatMoney = (amount: number | null | undefined, fallback = '--') => {
  if (isNil(amount) || isNaN(amount)) return fallback
  return amount
}

export const formatCurrency = (currency: string | null, fallback = '--') => {
  if (!currency) {
    return fallback
  }
  return getSymbolFromCurrency(String(currency).toUpperCase()) ?? '--'
}

export const cls = createTailwindMerge(() => {
  const config = getDefaultConfig()
  const editableConfig: any = config
  editableConfig.classGroups['text-color'] = [
    {
      text: [
        (value: string) => {
          const colorRegexps = [
            /^(slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)(-\d+)?$/,
            /^(input|ring|background|foreground)$/,
            /^(primary|secondary|accent|muted|destructive|band)(-\d+)?$/,
            /^wg-(gray|white|purple|red|yellow|green|orange|pink|blue)(-\d+)?$/,
            /^(icon|text|surface|border|avatar)(-.*)?$/,
            /^\[#([0-9a-fA-F]{3}){1,2}\]$/,
          ]
          const result = colorRegexps.some((regexp) => regexp.test(value))
          return result
        },
      ],
    },
  ]

  editableConfig.classGroups['font-size'] = [
    {
      text: [
        (value: string) => {
          const fontSizeRegexps = [
            /^(xs|sm|md|base|lg|xl|2xl|3xl|4xl|5xl|6xl|7xl|8xl|9xl|10xl)$/,
            /^(\d+(?:\.\d+)?|[\d.]+px)$/,
            /^(body|heading)-(xs|sm|md|base|lg|xl|2xl|3xl|4xl|5xl|6xl|7xl|8xl|9xl|10xl)$/,
            /^\[\d+(px|em|rem)\]$/,
          ]
          const result = fontSizeRegexps.some((regexp) => regexp.test(value))
          return result
        },
      ],
    },
  ]

  editableConfig.classGroups['shadow'] = [
    {
      shadow: [/^(wg-)?(xs|sm|md|base|lg|xl|2xl|3xl|4xl|5xl|6xl|7xl|8xl|9xl|10xl)$/],
    },
  ]

  return editableConfig
})

if (typeof window !== 'undefined') {
  window.cls = cls
}

let hashedOrigin = ''
export const getHashOrigin = () => {
  if (hashedOrigin) return hashedOrigin
  if (typeof window === 'undefined') {
    return ''
  }
  hashedOrigin = btoa(location.origin)
  return hashedOrigin
}

export const isUpgradePlan = (
  currentPlan?: Pick<Plan, 'tier_id' | 'is_free'>,
  targetPlan?: Pick<Plan, 'tier_id' | 'is_free'>,
) => {
  if (!currentPlan || !targetPlan) return false
  if (targetPlan.is_free) return false

  const currentTier = currentPlan.tier_id
  const targetTier = targetPlan.tier_id
  if (currentTier === targetTier) {
    return false
  }

  const tierOrder = ['L100', 'L200', 'L300']
  return tierOrder.indexOf(targetTier) > tierOrder.indexOf(currentTier)
}

export const noop = (...args: any[]) => {}

export const withHashedOriginQuery = (url: string): string => {
  const origin = getHashOrigin()
  return `${url}${url.includes('?') ? '&' : '?'}_d=${origin}`
}

export const isOldUser = () => {
  const createTime = getLocalStorage<string>(AMPLITUDE_USER_CREATE_TIME_KEY, {
    raw: true,
  })
  const createTimeDate = dayjs(createTime)
  if (createTime && createTimeDate.isValid() && createTimeDate.isBefore(dayjs().subtract(1, 'day'))) {
    return true
  }

  const lastMembershipRemindTime = getLocalStorage<string>(CACHE_KEY_SUBSCRIPTION_REMIND, { raw: true })
  const lastMembershipRemindTimeDate = dayjs(lastMembershipRemindTime)
  if (
    lastMembershipRemindTime &&
    lastMembershipRemindTimeDate.isValid() &&
    lastMembershipRemindTimeDate.isBefore(dayjs().subtract(1, 'day'))
  ) {
    return true
  }

  const persona = getLocalStorage('persona')
  if (persona) {
    return true
  }

  return false
}

export const addQueryParams = (url: string, params: AnyObject) => {
  try {
    const urlObj = new URL(url)
    for (const key of Object.keys(params)) {
      urlObj.searchParams.set(key, params[key])
    }
    return urlObj.toString()
  } catch (error) {
    return url
  }
}

// export const formatShortDuration = (seconds: number) => {
//   const hour = 60 * 60
//   const day = hour * 24
//   const month = day * 30
//   const year = month * 12

//   if (seconds >= year) {
//     const value = Math.floor(seconds / year)
//     return value === 1 ? `${value} year` : `${value} years`
//   } else if (seconds >= month) {
//     const value = Math.floor(seconds / month)
//     return value === 1 ? `${value} month` : `${value} months`
//   } else if (seconds >= day) {
//     const value = Math.floor(seconds / day)
//     return value === 1 ? `${value} day` : `${value} days`
//   } else if (seconds >= hour) {
//     const value = Math.floor(seconds / hour)
//     return value === 1 ? `${value} hour` : `${value} hours`
//   } else {
//     return 'Just now'
//   }
// }

// export const formatShortDurationWithTime = (time: string) => {
//   const seconds = dayjs().diff(dayjs(time), 'second')
//   return formatShortDuration(seconds)
// }

export const truthy = (value: any) => {
  if (value === 'undefined') {
    return false
  }

  return Boolean(value)
}

export const formatExtendCreditDuration = (duration: number): number => {
  if (!duration) {
    return 0
  }
  const rounded = Math.round(Math.max(0, duration))
  if ([0, 2, 4].includes(rounded)) {
    return rounded
  }

  if (rounded < 2) {
    return 2
  }
  if (rounded < 4) {
    return 4
  }

  return 4 * Math.round(rounded / 4)
}

export const getNextExtendDuration = (duration: number): number => {
  const defaultExtendDuration = 4
  if (!duration) {
    return defaultExtendDuration
  }
  if (duration >= 16) {
    return 0
  }

  // TODO: fix this temporary solution
  // return (16 - realDuration) % defaultExtendDuration || defaultExtendDuration
  // return duration === 2 ? 2 : defaultExtendDuration
  return Math.round(duration) <= 3 ? 2 : defaultExtendDuration
}

export const fileSize = (size: number): string => {
  const result = filesize(size, { base: 2 })
  return result?.replace(/iB$/, 'B')
}

export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getMailToLink = (params: { email: string; subject?: string; body?: string }) => {
  const { email, subject, body } = params
  const mailto = email?.startsWith('mailto:') ? email : `mailto:${email}`
  const query = {
    subject: subject ?? undefined,
    body: body ?? undefined,
  }
  const search = qs.stringify(query)
  return [mailto, search].filter(Boolean).join('?')
}

const isEqualFloat = (a: number, b: number) => {
  return Math.abs(a - b) < Number.EPSILON
}

export const formatAspectRatio = (aspectRatio: string | number): string => {
  if (typeof aspectRatio === 'number' && isEqualFloat(aspectRatio, 16 / 9)) {
    return '16:9'
  }
  if (typeof aspectRatio === 'number' && aspectRatio === 1) {
    return '1:1'
  }
  return String(aspectRatio)
}

export const getSizeByResolutionAndAspectRatio = (params: { resolution: number; aspectRatio: string }): Size | null => {
  const map: Record<number, Record<string, Size>> = {
    720: {
      '16:9': { width: 1280, height: 720 },
      '4:3': { width: 1120, height: 840 },
      '1:1': { width: 960, height: 960 },
      '9:16': { width: 720, height: 1280 },
      '3:4': { width: 840, height: 1120 },
      '21:9': { width: 1472, height: 640 },
    },
    1080: {
      '16:9': { width: 1920, height: 1080 },
      '4:3': { width: 1680, height: 1264 },
      '1:1': { width: 1440, height: 1440 },
      '9:16': { width: 1080, height: 1920 },
      '3:4': { width: 1264, height: 1680 },
      '21:9': { width: 2208, height: 960 },
    },
    2160: {
      '16:9': { width: 3840, height: 2160 },
      '4:3': { width: 3200, height: 2400 },
      '1:1': { width: 2880, height: 2880 },
      '9:16': { width: 2160, height: 3840 },
      '3:4': { width: 2400, height: 3200 },
      '21:9': { width: 4200, height: 1800 },
    },
  }
  return map[params.resolution]?.[params.aspectRatio] ?? null
}
export const isValidSeed = (seed: string | number | null | undefined) => {
  // seed is neither nil or empty string or -1
  return (
    seed !== null &&
    seed !== undefined &&
    seed !== '' &&
    seed !== -1 &&
    seed !== '-1' &&
    String(Number(seed)) === String(seed)
  )
}

/**
 * Do full text search in given string and return true if all keywords are found
 * @param text string to search
 * @param keyword keyword to search
 * @returns
 */
export const fullTextSearch = (text: string, keyword: string): boolean => {
  const lowText = text.toLowerCase()
  const lowKeyword = keyword.toLowerCase()
  const parts = lowKeyword.trim().replace(/\s+/, ' ').split(' ')
  const fullMatched = parts.every((part) => lowText.includes(part))
  return fullMatched
}

export const convertGSLinkToUrl = (gsLink: string | undefined): string => {
  if (!gsLink) return ''
  return formatCDN(gsLink.replace('gs://haiper_vb/', 'https://cdnvb1.haiper.ai/')) ?? ''
}

export const isCreation = (data: CreationBase): data is Creation => {
  if (!data) {
    return false
  }

  const keys = Object.keys(data)

  const creationKeys = [
    // 'is_collected',
    'outputs',
    // 'status'
  ]
  const creationOutputKeys = [
    'output_id',
    'output_url',
    'output_create_time',
    'output_update_time',
    // 'comment_num'
  ]

  return creationKeys.every((key) => keys.includes(key)) && creationOutputKeys.every((key) => !keys.includes(key))
}

export const isCreationOutput = (data: CreationBase): data is CreationOutput => {
  if (!data) {
    return false
  }

  const keys = Object.keys(data)
  const creationOutputKeys = [
    'output_id',
    'output_url',
    'output_create_time',
    'output_update_time',
    // 'comment_num'
  ]

  return creationOutputKeys.every((key) => keys.includes(key))
}

export function getCreationDetailUrl(creation: CreationBase) {
  if (!creation) {
    return ''
  }

  const creationId = creation.creation_id
  let outputId = ''
  if ((creation as CreationOutput).output_id) {
    outputId = (creation as CreationOutput).output_id ?? ''
  } else if (isCreation(creation)) {
    outputId = creation.outputs?.[0]?.id ?? ''
  }

  const result =
    outputId && creation?.output_type !== 'video'
      ? `/creation/${creationId}?output_id=${outputId}`
      : `/creation/${creationId}`
  return result
}

export function calculateAspectRatio(width: number | null | undefined, height: number | null | undefined): number {
  if (!width || !height) {
    return 16 / 9
  }
  const aspectRatio = width / height
  const maxAspectRatio = 16 / 9
  const minAspectRatio = 3 / 4

  return Math.min(Math.max(aspectRatio, minAspectRatio), maxAspectRatio)
  // return aspectRatio
}

const disableAudioTrackPreloadDetection = true

export const videoHasAudioTrack = async (
  video: HTMLVideoElement | null | undefined,
  tryPlay = false,
): Promise<boolean> => {
  const v: any = video
  if (!v) return false

  if (v.audioTracks?.length || v.mozHasAudio || v.webkitAudioDecodedByteCount) {
    return true
  }

  if (tryPlay && !disableAudioTrackPreloadDetection) {
    try {
      return await new Promise((resolve, reject) => {
        const newVideo: any = document.createElement('video')
        newVideo.src = v.src
        newVideo.crossOrigin = 'Anonymous'
        newVideo.load()
        newVideo.preload = 'auto'
        newVideo.muted = true

        newVideo.onloadedmetadata = async () => {
          newVideo.play()
        }

        newVideo.onTimeUpdate = () => {
          const res = newVideo.audioTracks?.length || newVideo.mozHasAudio || newVideo.webkitAudioDecodedByteCount
          newVideo.pause()
          newVideo.src = ''
          resolve(!!res)
        }
        newVideo.onerror = () => {
          reject(false)
        }
      })
    } catch (error) {
      // do nothing
    }
  }

  return false
}

export const findNearestNumber = (target: number, numbers: number[]): number => {
  return numbers.reduce((prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev))
}

export const hasUTMAttributes = () => {
  return UTM_FIELDS.some((field) => {
    return getLocalStorage(field, { raw: true }) !== null
  })
}

export const snakeToSpace = (text: string) => {
  return text.split('_').map(capitalize).join(' ')
}
